<template>
    <modal ref="modalDuplicarCupones" titulo="Duplicar cupón" icon="duplicar_cupon" no-aceptar adicional="Duplicar" :cargado-modal="loading" @adicional="duplicarCupon">
        <div class="row mx-0 justify-center">
            <div class="col-10">
                <p class="pl-3 text-general f-14">
                    Carpeta
                </p>
                <el-select v-model="form.id_carpeta" placeholder="Select" class="w-100">
                    <el-option
                    v-for="item in carpetas"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-auto px-4 d-middle py-2">
                <el-checkbox v-model="form.incluir_clientes" class="mx-2" /> incluir los {{$config.cliente + 's'}} específicos
            </div>
        </div>
    </modal>
</template>

<script>
import Cupones from '~/services/cupones/cupones'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            carpetas:[],
            form:{
                id_carpeta:null,
                id_cupon:null,
                incluir_clientes:false
            },
            loading:false
        }
    },
    computed:{
        ...mapGetters({
            cupon: 'cupones/cupones/datosDetalle'
        }),
        id_cupon(){
            return Number(this.$route.params.id_cupon);
        },
    },
    methods: {
        toggle(){
            this.$refs.modalDuplicarCupones.toggle();
            this.listadoCarpetas()
        },
        async listadoCarpetas(){
            try {
                const id_cedis = 0
                const tipoVista = this.cupon.destinatario // 1 Cliente  2 Leechero
                const {data} = await Cupones.listarCarpetas(id_cedis, tipoVista)
                this.carpetas = data.carpetas

            } catch (e){
                this.error_catch(e)
            }
        },
        async duplicarCupon(){
            try {

                if(this.form.id_carpeta === null) return
                this.loading = true
                this.form.id_cupon = this.id_cupon
                const {data} = await Cupones.duplicarCupon(this.id_cupon,this.form)
                this.notificacion('Éxito','Se han duplicado todos los datos de este cupon en la carpeta asignada','success')
                this.$refs.modalDuplicarCupones.toggle();

            } catch (e){
                this.error_catch(e)
            } finally{
                this.loading = false
            }
        }
    }
}
</script>

<style>

</style>
